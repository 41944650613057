import React, { useState } from "react";
import { navigate } from "gatsby";
import $ from 'jquery'

function SearchResultsPagination(props) {
    const [pagenumber, setPageNumber] = useState(props.current_page_number+1);

    const prevpage = event => {
        setPageNumber((props.current_page_number+1) - 1)
        let pageUrl = 'page-' + ((props.current_page_number+1) - 1) + '/';
        //get current url
        const urlwithoutsort = (props?.location_path).split("page-");
        // concat url and navigate
        if(props.current_page_number == 1) {
            navigate(urlwithoutsort[0])
        }
        else {
            navigate(urlwithoutsort[0] + pageUrl)
        }
        var target = $("body");
        $('html,body').animate({
            scrollTop: target.offset().top - 250
        },100);
    }
    return (
        <React.Fragment>
        {props.current_page_number+1 > 1 && <section className="more-results w-100 pb-4 pt-0">
            <div className="more-btn">
                <button
                    className="btn"
                    id="mynextbut"
                    onClick={prevpage}
                // disabled={!hasMore}
                >
                    Load Previous
                </button>
            </div>
        </section> }
        </React.Fragment>
    );
}

export default SearchResultsPagination