import React, { useState } from "react";
import { navigate } from "gatsby";
import $ from 'jquery'

function SearchResultsPagination(props) {
    const [pagenumber, setPageNumber] = useState(props.current_page_number+1);

    const nextpage = event => {
        setPageNumber((props.current_page_number+1) + 1)
        let pageUrl = 'page-' + ((props.current_page_number+1) + 1) + '/';
        //get current url
        const urlwithoutsort = (props?.location_path).split("page-");
        // concat url and navigate
        navigate(urlwithoutsort[0] + pageUrl)
        var target = $("body");
        $('html,body').animate({
            scrollTop: target.offset().top - 250
        },100);
    }
    return (
        <section className="more-results w-100 pb-4">
            <div className="more-btn">
                <button
                    className="btn"
                    id="mynextbut"
                    onClick={nextpage}
                // disabled={!hasMore}
                >
                    Load More
                </button>
            </div>
        </section>
    );
}

export default SearchResultsPagination