import React, { useState, useEffect } from "react"
import ScrollAnimation from "react-animate-on-scroll"
import getVideoId from "get-video-id"
import SearchResultsImagePopup from "@Components/SearchResults/SearchResultsImagePopup"
import SearchResultsFloorplanPopup from "@Components/SearchResults/SearchResultsFloorplanPopup"
import PlayVideo from "@Components/PlayVideo/PlayVideo"
import { useWindowSize } from "../../hooks/window-size"
import Valuation from "@Components/SearchResults/Valuation/Valuation"
import { ShowProcessedImage } from "@Components/common/ggfx-client/module/components/show-image"
import imageConfig from "../../../static/images/config.json"
import NoImage from "../../images/no-image.png"
import Content from "../Content/Content"
import { Link } from "gatsby"
import { Form, Container, Row, Col, Fade, Modal } from "react-bootstrap"

import {
    UserObjectStoreProvider,
    SaveItem,
    getUserobjectData,
    isAuthenticated,
    savedSearchParams,
} from "@myaccount/website-service"

const PropertyCard = (props) => {


    var hit = props.hit
    // property details url structure
    let uriStr = ""

    if (hit.search_type === "sales" && hit.department === "newdevelopments") {
      uriStr = `new-home-for-sale/`
    }
    // property details url structure

    // available days
    let currFullDate = new Date()
    let currYear = currFullDate.getFullYear()
    let currMonth = currFullDate.getMonth() + 1
    let currDate = currFullDate.getDate()
    let currDateCal = currYear + "-" + currMonth + "-" + currDate
    let propertyDate = hit.AvailableFrom
    let availableFrom = ""
    let date1 = new Date(propertyDate).getTime()
    let date2 = new Date(currDateCal).getTime()

    if (date1 > date2) {
      var timeDiff = Math.abs(date1 - date2)
      // days difference
      var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24))
      availableFrom = diffDays
    } else {
    }
    // available days

    var prop_price=hit?.price_qualifier === "POA"
    ? "€ Price on Application"
    : hit.price > 10000 && hit?.search_type !== "lettings"
    ?
   `€${parseFloat(hit.price).toLocaleString()}`
    : 
    hit?.search_type === "lettings" &&hit.price>100?
    `€${parseFloat(hit.price).toLocaleString()} ${                            
      hit?.price_qualifier
        ? hit?.price_qualifier
        : ""
    }`
    : "€ Price on Application"

    // display address
    var propertyAddressFinal = ""

    if (hit.display_address === undefined) {
      propertyAddressFinal = hit.display_address
    } else {
      var propertyAddress = hit.display_address
      var propertySplit = propertyAddress.split(",")

      for (var i = 0; i < propertySplit.length; i++) {
        // Trim the excess whitespace.
        propertySplit[i] = propertySplit[i]
          .replace(/^\s*/, "")
          .replace(/\s*$/, "")
        // Add additional code here, such as:
        propertyAddressFinal = propertySplit[0]
      }
    }

    // html = '<a href="/'+uriStr+hit.slug+'-'+hit.objectID+'"><div class="result-cards"><div class="contact-card"><figure><img src="'+(hit.images1 && (hit.images1.length > 0) ? hit.images1[0]['640x422'] : NoImage)+'" /><span class="card-tag"></span></figure><div class="info"><h2>€'+hit.price.toLocaleString()+''+(hit.search_type === "lettings" ? " PCM" : "")+'</h2><h4 class="card-title">'+hit.display_address+'</h4><div class="d-flex justify-content-start"><div class="property-map-icon d-flex align-items-center"><i class="icon-bed"></i><span>'+hit.bedroom+''+(hit.bedroom > 1 ? " Bedrooms" : " Bedroom")+'</span></div><div class="property-map-icon d-flex align-items-center"><i class="icon-bathroom"></i><span>'+hit.bathroom+''+(hit.bathroom > 1 ? " Baths" : " Bath")+'</span></div></div></div></div></div></a>';

    var property_url = "/" + uriStr + hit.slug + "-" + hit.objectID
    var img_url = hit.images && hit.images.length != 0 ? hit.images[0]["600x400"] : NoImage

    return (
        <>
            <a href={`${property_url}`}>
                <div class="property-card map_card">
                    <div class="property-card-wrapper">
                        <div class="property-img">
                            <img src={img_url} alt="property" />

                            <div class="property-tag">{hit.status}</div>
                        </div>
                        <div class="property-details">
                            <div class="property-name">
                                <a href={`${property_url}`}>
                                    {hit.display_address}
                                </a>

                            </div>
                            <p class="property-map-desc">
                                {hit.description && hit.description.slice(0, 100)}<span><a href={`${property_url}`}>{hit.description ? "...Read more" : ""}</a></span>
                            </p>

                            <div class="property-price">
                                {prop_price}
                            </div>
                            <div class="bottom-block">
                                {parseInt(hit.bedroom) > 0 ?
                                    <div class="bedroom-count">
                                        <i class="icon-bed"></i>{hit.bedroom}
                                    </div> : ""}
                                {parseInt(hit.bathroom) > 0 ? <div class="bathroom-count">
                                    <i class="icon-tub"></i>{hit.bathroom}
                                </div> : ""}
                                {parseInt(hit.reception) > 0 ? <div class="lounge-count d-none d-xl-flex">
                                    <i class="icon-couch"></i>{hit.reception}</div> : ""}
                                {parseInt(hit.floorarea_min) > 0 ? <div class="property-area">
                                    <i class="icon-cube"></i>
                                    {(hit.floorarea_type === "squareMetres" || hit.floorarea_type === "Square meters") ?
                                        hit?.floorarea_min : ""

                                    }
                                    {(hit.floorarea_type == "squareFeet" || hit.floorarea_type === "Square feet") ?
                                        (hit.floorarea_min / 10.7639)
                                            .toFixed(1)
                                            .replace(".0", "")
                                        : ""}{" "}{"m"}
                                    <sup>2</sup>
                                </div>
                                    : ""
                                }
                            </div>


                        </div>
                    </div>
                </div>
            </a>
        </>
    )
}


export default PropertyCard