import React, { useState, useEffect } from "react"
import ScrollAnimation from "react-animate-on-scroll"
import getVideoId from "get-video-id"
import SearchResultsImagePopup from "@Components/SearchResults/SearchResultsImagePopup"
import SearchResultsFloorplanPopup from "@Components/SearchResults/SearchResultsFloorplanPopup"
import PlayVideo from "@Components/PlayVideo/PlayVideo"
import { useWindowSize } from "../../hooks/window-size"
import Valuation from "@Components/SearchResults/Valuation/Valuation"
import { ShowProcessedImage } from "@Components/common/ggfx-client/module/components/show-image"
import imageConfig from "../../../static/images/config.json"
import NoImage from "../../images/no-image.png"
import Content from "../Content/Content"
import { Link } from "gatsby"
import { Form, Container, Row, Col, Fade, Modal } from "react-bootstrap"

import {
    UserObjectStoreProvider,
    SaveItem,
    getUserobjectData,
    isAuthenticated,
    savedSearchParams,
} from "@myaccount/website-service"

const PropertyCard = (props) => {

    const [windowWidth] = useWindowSize()
    const [isPlay, setIsPlay] = useState(false)
    const [videoid, setVideoId] = useState("")
    const [video_type, setVideoType] = useState("")

    const playVideoHandler = (video_url, video_type) => {
        setIsPlay(true)
        setVideoId(video_url)
        setVideoType(video_type)
    }

    // useEffect(() => {
    //     if (typeof window !== undefined) {
    //         if (window.scrollY) {
    //             window.scroll(0, 0)
    //         }
    //     }
    // }, [])

    const [show, setShow] = useState(false)
    const [fullscreen, setFullscreen] = useState(true)

    function handleShow(video_url, video_type) {
        setShow(true)
        setVideoId(video_url)
        setVideoType(video_type)
    }
    // Video


    let uriStr = ""
    let mysale = ""

    var hit = props.hit

    if (hit.search_type === "sales" && hit.department === "residential") {
        uriStr = "property-for-sale/"
      } else if (
        hit.search_type === "lettings" &&
        hit.department === "residential"
      ) {
        uriStr = `property-to-rent/`
      } else if (hit.search_type === "sales" && hit.department === "commercial") {
        uriStr = `commercial-property-for-sale/`
      } else if (
        hit.search_type === "lettings" &&
        hit.department === "commercial"
      ) {
        uriStr = `commercial-property-to-rent/`
      }
    // property details url structure

    // Video id
    let videotourid = hit.video_tour && getVideoId(hit.video_tour)

    let virtualid = hit.virtual_url && getVideoId(hit.virtual_url)

    // let virtualTourId=hit.virtual_tour&&getVideoId(hit.virtual_tour);
    let virtualTourId = hit.virtual_tour && hit.virtual_tour !== true && getVideoId(hit.virtual_tour);

    // Video

    if (typeof window !== "undefined" && window) {
        var s = document?.getElementById("chat-widget-container")
        var t = document?.getElementById("livechat-eye-catcher")

        if (s) {
            s.style.display = 'none'
        }
        if (t) {
            t.style.display = 'none'
        }
    }

    return (
        <>
            <UserObjectStoreProvider>
                    <div className="property-card map_card">
                        <div className="property-card-wrapper">
                            <div className="property-img">
                                {hit?.images && hit?.images.length != 0 ? (
                                    <Link to={`/${uriStr}${hit.slug}-${hit.objectID}/`}>
                                        <ShowProcessedImage
                                            images={hit.images[0]}
                                            attr={{
                                                className: "mb-0 img-fluid",
                                                alt: `${hit.display_address} - DNG Estate Agents`,
                                            }}
                                            transforms={
                                                imageConfig.property.searchResults.sizes
                                            }
                                        />
                                    </Link>
                                ) : (
                                    <Link to={`/${uriStr}${hit.slug}-${hit.objectID}/`}>
                                        <img src={NoImage} alt="property" />
                                    </Link>
                                )}
                                <div className="property-wrapper">
                                    <div className="left-block">
                                        {hit?.images && hit?.images?.length != 0 && (
                                            <SearchResultsImagePopup
                                                propertyId={hit.objectID}
                                                propertyImage={hit?.images}
                                                propertyImageLrg={hit?.images_src}
                                                imageCount={hit?.imageCount}
                                            />
                                        )}
                                        {/* <div className="image-wrap">
                                <i className="icon-camera"></i>
                                {
                                    hit.images.length > 0 &&
                                    <div className="image-count">1/{hit.images.length}</div>
                                }
                            </div> */}
                                        <div className="property-view">
                                            {hit.video_tour &&
                                                (videotourid?.service === "youtube" ? (
                                                    <div className="video-wrap">
                                                        <button
                                                            onClick={() =>
                                                                playVideoHandler(
                                                                    hit.video_tour,
                                                                    "youtube"
                                                                )
                                                            }
                                                        >
                                                            <i className="icon-play"></i>
                                                        </button>
                                                    </div>
                                                ) : (
                                                    <div className="video-wrap">
                                                        <button
                                                            onClick={() =>
                                                                handleShow(hit.video_tour, "custom_video")
                                                            }
                                                        >
                                                            <i className="icon-play"></i>
                                                        </button>
                                                    </div>
                                                ))}

                                            {hit.virtual_tour &&
                                                (virtualTourId?.service === "youtube" ? (
                                                    <div className="video-wrap">
                                                        <button
                                                            onClick={() =>
                                                                playVideoHandler(
                                                                    hit.video_tour,
                                                                    "youtube"
                                                                )
                                                            }
                                                        >
                                                            <i className="icon-play"></i>
                                                        </button>
                                                    </div>
                                                ) : (
                                                    <div className="video-wrap">
                                                        <button
                                                            onClick={() =>
                                                                handleShow(hit.virtual_tour, "custom_video")
                                                            }
                                                        >
                                                            <i className="icon-play"></i>
                                                        </button>
                                                    </div>
                                                ))}

                                            {hit.virtual_url &&
                                                (virtualid?.service === "youtube" ? (
                                                    <div className="video-wrap">
                                                        <button
                                                            onClick={() =>
                                                                playVideoHandler(
                                                                    hit.virtual_url,
                                                                    "youtube"
                                                                )
                                                            }
                                                        >
                                                            <i className="icon-play"></i>
                                                        </button>
                                                    </div>
                                                ) : (
                                                    <div className="video-wrap">
                                                        <button
                                                            onClick={() =>
                                                                handleShow(
                                                                    hit.virtual_url,
                                                                    "custom_video"
                                                                )
                                                            }
                                                        >
                                                            <i className="icon-play"></i>
                                                        </button>
                                                    </div>
                                                ))}

                                            {hit?.floorplan?.url ? (
                                                <SearchResultsFloorplanPopup
                                                    propertyId={hit.objectID}
                                                    propertyFloorPlan={hit?.floorplan}
                                                />
                                            ) : null}
                                        </div>
                                    </div>

                                    {hit.department === "residential" && hit.status !== "Sold" && (
                                        <SaveItem
                                            type="property"
                                            pid={hit.objectID}
                                            // userObjects={userObjects}
                                        >
                                            <div className="right-block">
                                                <button type="button">
                                                    <i className="icon-heart" />
                                                    <span className="btn-text d-none d-md-inline-block"></span>
                                                </button>
                                            </div>
                                        </SaveItem>
                                    )}
                                </div>
                                {hit.status &&
                                    hit.status !== "For Sale" &&
                                    hit.status !== "To Let" && (
                                        <div className="property-tag">{hit.status}</div>
                                    )}
                            </div>
                            <div className="property-details">
                                <div className="property-name">
                                    <Link to={`/${uriStr}${hit.slug}-${hit.objectID}/`}>
                                        {hit.display_address && hit.display_address}
                                        {/* <br />{hit.title && hit.title} */}
                                    </Link>
                                </div>
                                <p className="property-map-desc">
                                    {hit.description &&
                                        <Content
                                            Content={hit.description}
                                            page="search-result"
                                            uriStr={uriStr}
                                            hit={hit}
                                        />
                                    }
                                </p>
                                <div className="property-price">
                                    {hit?.price_qualifier === "POA"
                                        ? "€ Price on Application"
                                        : hit.price > 10000 && hit?.search_type !== "lettings"
                                            ?
                                            `€${parseFloat(hit.price).toLocaleString()}`
                                            :
                                            hit?.search_type === "lettings" && hit.price > 100 ?
                                                `€${parseFloat(hit.price).toLocaleString()} ${hit?.price_qualifier
                                                    ? hit?.price_qualifier === "Per Month" ? "pcm" : hit?.price_qualifier === "Per Week" ? "pw" : hit?.price_qualifier
                                                    : ""
                                                }`
                                                : "€ Price on Application"}
                                </div>
                                <div className="bottom-block">
                                    {hit.bedroom >= 1 && (
                                        <div className="bedroom-count">
                                            <i className="icon-bed"></i>
                                            {hit.bedroom}
                                        </div>
                                    )}
                                    {hit.bathroom >= 1 && (
                                        <div className="bathroom-count">
                                            <i className="icon-tub"></i>
                                            {hit.bathroom}
                                        </div>
                                    )}
                                    {hit.reception >= 1 && (
                                        <div className="lounge-count d-none d-xl-flex">
                                            <i className="icon-couch"></i>
                                            {hit.reception}
                                        </div>
                                    )}
                                    {hit.floorarea_min >= 1 && (
                                        <div className="property-area">
                                            <i className="icon-cube"></i>
                                            {/* {`${hit.floorarea_min}  ${hit.floorarea_type && (hit.floorarea_type === "squareFeet" ? "ft" : "m")}`} */}
                                            {(hit.floorarea_type === "squareMetres" || hit.floorarea_type === "Square meters") &&
                                                hit?.floorarea_min

                                            }
                                            {(hit.floorarea_type == "squareFeet" || hit.floorarea_type === "Square feet") &&
                                                (hit.floorarea_min / 10.7639)
                                                    .toFixed(1)
                                                    .replace(".0", "")
                                            }{" "}
                                            {"m"}
                                            <sup>2</sup>
                                        </div>
                                    )}
                                    {hit.extrasField && hit.extrasField.pBERRating && (
                                        <div className="property-label">
                                            <div className="d-flex justify-content-start align-items-center property-ber-rating">
                                                <div className="ber-black">BER</div>
                                                <div
                                                    className={
                                                        "ber-green " +
                                                        hit.extrasField.pBERRating.toLowerCase()
                                                    }
                                                >
                                                    {hit.extrasField.pBERRating}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
            </UserObjectStoreProvider>
        </>
    )
}


export default PropertyCard