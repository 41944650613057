import React, { useState, useEffect } from "react";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const SearchResultsImagePopup = (props) => {

    // Property details images lightbox
    var propertyImagePopup = []
    
    const [photoIndex, setPhotoIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false);

    const openLighboxMobile = (e, ind) => {
        e.preventDefault();
        setPhotoIndex(ind);
        setIsOpen(true);
    } 

    if(props.department === "new_homes"){
        props?.propertyImageLrg ? props?.propertyImageLrg.filter(item => item !== null).map(item => propertyImagePopup.push(item)) : props?.propertyImage?.length > 0 && props?.propertyImage.filter(item => item !== null).map(item => propertyImagePopup.push(item['1600x1066'] ? item['1600x1066'] : item['600x400']))
    } else{
        props?.propertyImageLrg ? props?.propertyImageLrg.filter(item => item !== null).map(item => propertyImagePopup.push(item)) : props?.propertyImage?.length > 0 && props?.propertyImage.filter(item => item !== null).map(item => propertyImagePopup.push(item['1600x1066'] ? item['1600x1066'] : item['440x320']))
    }
    
    
    //propertyImagePopup.push(props?.propertyImage && props?.propertyImage[0]['440x320'])
	// Property details images lightbox 
    if(props?.imgLength){
        propertyImagePopup = propertyImagePopup.slice(0, props?.imgLength)
    }
    var image_count = propertyImagePopup.length;
   
    const imageCount=props.imageCount

    return (
        <React.Fragment>
            {
                props.tag === "auction" ? 
                <li className="list-inline-item auction-floorplan">
                    <a href="javascript:void(0)" onClick={(e) => openLighboxMobile(e,0)}>
                        <i className="icon-camera-black"></i>
                    </a>                    
                    {
                        image_count != 0 &&
                        <span className="">1/{imageCount}</span>
                    }                    
                </li>
                :
                <div className="image-wrap" onClick={(e) => openLighboxMobile(e,0)}>
                    <i className="icon-camera" ></i>
                    {
                        image_count != 0 &&
                        <div className="image-count">1/{imageCount}</div>
                    }
                </div>
            }

            {isOpen && propertyImagePopup.length != 0 && (
                <Lightbox
                    wrapperClassName="light-box-image-zoom"
                    mainSrc={propertyImagePopup[photoIndex]}
                    nextSrc={
                        propertyImagePopup[
                            (photoIndex + 1) % propertyImagePopup.length
                        ]
                    }
                    prevSrc={
                        propertyImagePopup[
                            (photoIndex + propertyImagePopup.length - 1) %
                            propertyImagePopup.length
                        ]
                    }
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() =>
                        setPhotoIndex((photoIndex + propertyImagePopup.length - 1) % propertyImagePopup.length)
                    }
                    onMoveNextRequest={() =>
                        setPhotoIndex((photoIndex + 1) % propertyImagePopup.length)
                    }
                />
            )}
        </React.Fragment>
    )
}

export default SearchResultsImagePopup