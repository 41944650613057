import React, { Fragment } from 'react';
import {Row, Col } from "react-bootstrap"
import {
    SaveItem,
  } from "@myaccount/website-service"

const CreateAlert = (props) => {

	return (
		<Fragment>
             <div className="property-card-detailed w-100">
                {/* <SaveItem
                {...props}
                type="search"
                > */}
               
                    <Row>
                    <Col lg={12} className="no-properties-text">
                        {/* <h2 className="mb-0">Unfortunately, we do not currently have any properties that match your search criteria.</h2> */}
                        <div className="property-details no-property-details">
                            <i className="icon-home"></i>
                            {/* <h3><span className="reg-alt-txt">Register</span> for Alerts</h3>                         */}
                            <div className="property-names">
                            We couldn't find what you're looking for right now, 
                            please refine your search or view properties below which may interest you.
                            </div>                                              
                        {/* <button className="btn myac-save-btn">Create Alert</button> */}
                        {/* <button className="btn myac-save-btn-saved">Alert Created</button> */}
                        </div>
                    </Col>
                    </Row>
                {/* </SaveItem> */}
            </div>
		</Fragment>
	)
}

export default CreateAlert;
