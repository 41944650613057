import React, { useState, useEffect } from "react"
import ScrollAnimation from "react-animate-on-scroll"
import getVideoId from "get-video-id"
import moment from "moment"
import { useWindowSize } from "../../hooks/window-size"
import { ShowProcessedImage } from "@Components/common/ggfx-client/module/components/show-image"
import imageConfig from "../../../static/images/config.json"
import NoImage from "../../images/no-image.png"
import { Link } from "gatsby"
import { Form, Container, Row, Col, Fade, Modal } from "react-bootstrap"

const PropertyCard = (props) => {


    var hit = props.hit
    // property details url structure
    let uriStr = ""

    if (hit.search_type === "sales" && hit.department === "residential") {
      uriStr = "property-for-sale/"
    } else if (
      hit.search_type === "lettings" &&
      hit.department === "residential"
    ) {
      uriStr = `property-to-rent/`
    } else if (hit.search_type === "sales" && hit.department === "commercial") {
      uriStr = `commercial-property-for-sale/`
    } else if (
      hit.search_type === "lettings" &&
      hit.department === "commercial"
    ) {
      uriStr = `commercial-property-to-rent/`
    }
    // property details url structure

    // available days
    let currFullDate = new Date()
    let currYear = currFullDate.getFullYear()
    let currMonth = currFullDate.getMonth() + 1
    let currDate = currFullDate.getDate()
    let currDateCal = currYear + "-" + currMonth + "-" + currDate
    let propertyDate = hit.AvailableFrom
    let availableFrom = ""
    let date1 = new Date(propertyDate).getTime()
    let date2 = new Date(currDateCal).getTime()

    if (date1 > date2) {
      var timeDiff = Math.abs(date1 - date2)
      // days difference
      var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24))
      availableFrom = diffDays
    } else {
    }
    // available days

    // display address
    var propertyAddressFinal = ""

    if (hit.display_address === undefined) {
      propertyAddressFinal = hit.display_address
    } else {
      var propertyAddress = hit.display_address
      var propertySplit = propertyAddress.split(",")

      for (var i = 0; i < propertySplit.length; i++) {
        // Trim the excess whitespace.
        propertySplit[i] = propertySplit[i]
          .replace(/^\s*/, "")
          .replace(/\s*$/, "")
        // Add additional code here, such as:
        propertyAddressFinal = propertySplit[0]
      }
    }

    // html = '<a href="/'+uriStr+hit.slug+'-'+hit.objectID+'"><div class="result-cards"><div class="contact-card"><figure><img src="'+(hit.images1 && (hit.images1.length > 0) ? hit.images1[0]['640x422'] : NoImage)+'" /><span class="card-tag"></span></figure><div class="info"><h2>€'+hit.price.toLocaleString()+''+(hit.searchType === "lettings" ? " PCM" : "")+'</h2><h4 class="card-title">'+hit.display_address+'</h4><div class="d-flex justify-content-start"><div class="property-map-icon d-flex align-items-center"><i class="icon-bed"></i><span>'+hit.bedroom+''+(hit.bedroom > 1 ? " Bedrooms" : " Bedroom")+'</span></div><div class="property-map-icon d-flex align-items-center"><i class="icon-bathroom"></i><span>'+hit.bathroom+''+(hit.bathroom > 1 ? " Baths" : " Bath")+'</span></div></div></div></div></div></a>';

    var title_val = hit.title ? hit.title : hit.bedroom ? hit.bedroom + " bedrooms property for sale" : "";

    return (
        <div class="property-card map_card">
            <div class="property-card-wrapper">
                <div class="property-img">
                    <a href={`${hit.lotDetailsUrl}`} target="_blank">
                        <img src={hit.images && hit.images.length != 0 ? hit.images[0]["440x320"] : NoImage} alt="property" />
                    </a>
                </div>
                <div class="property-details auction-details">
                    <div class="property-name">
                        <a href={`${hit.lotDetailsUrl}`} target="_blank">
                            {hit.display_address}
                        </a>
                    </div>
                    <div class="property-bedroom-details">
                        <div class="property-bedroom-text">
                            {hit.description}
                        </div>
                    </div>
                    <p class="price"><i class="icon-currency"></i> AMV: €{hit.price?.toLocaleString()}</p>
                    <p class="clock-txt"><i class="icon-property-clock"></i> Auction Ends {moment(hit.endDate).format("Do MMMM YYYY")} {moment(hit.endDate).format("h.mma")}</p>
                    <div class="bottom-block mt-0">
                        <div class="valuation-cta w-100">
                            <a href={`${hit.lotDetailsUrl}`} target="_blank" class="btn w-100">View / Bid</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default PropertyCard